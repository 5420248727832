@import "variables";

.font-black {
  color: $black;
}

.font-primary-dark {
  color: $primary-dark;
}

.font-primary {
  color: $primary;
}

.font-secondary {
  color: $secondary;
}

.font-secondary-dark {
  color: $primary;
}

.font-white {
  color: $white;
}

.font-gray {
  color: $gray;
}