@import "~tachyons";
@import "variables";
@import "font";

.title {
  @extend .font-primary;
  @extend .tc;
  @extend .mb4;
  text-decoration: underline;
  text-decoration-color: $secondary-dark;
}

.nav-bar-margin {
  margin-bottom: 88px !important;
}